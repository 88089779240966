// axiosInstance.js
import axios from 'axios';
// const LOCAL_URL=''

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/api', // Replace with your API base URL
  baseURL: 'https://iil-production-9866023dacd5.herokuapp.com/parse/functions', // Replace with your API base URL
  timeout: 10000, // Optional: Set a timeout for requests (in milliseconds)
  headers: {
    'Content-Type': 'application/json',
    // Add any other custom headers here
  },
});

// Optional: Add request interceptors
axiosInstance.interceptors.request.use(
  config => {
    // Do something before request is sent
    // e.g., add an authorization token
    console.log("axoius-data****", JSON.parse(localStorage.getItem('storedUserData')))
    const userDetails = JSON.parse(localStorage.getItem('storedUserData'));

    const { sessionToken = '' } = userDetails || {}
    //const token = localStorage.getItem('token');
    if (sessionToken) {
      config.headers['x-auth-token'] = `${sessionToken}`;
    }
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Optional: Add response interceptors
axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lies within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors (e.g., redirect to login)
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
